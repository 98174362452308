<template>
    <div>
      <validation-observer ref="simple">
        <b-form>
          <b-card-code title="  تعديل  طلب">
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="">
                <b-form-group
                  label-for="transaction_id"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> Transaction</label>
                <v-select
                  v-model="transaction_id"
               
                  :reduce="(val) => val.value"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="4">
              <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                <label>تاريخ الانتهاء </label>
                <flat-pickr v-model="deadline"    class="form-control"
                    placeholder="حدد تاريخ " />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="">
                <b-form-group
                  label-for="notes"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> ملاحظة</label>
                <b-form-input v-model="notes" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="update" >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل طلب الشراء</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
          </b-card-code>
        </b-form>
      </validation-observer>
      <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
    </div>
  </template>
  
  <script>
  import "vue-good-table/dist/vue-good-table.css";
  import { VueGoodTable } from "vue-good-table";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { required, max, is_earlier } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  
  import {
    BRow,
    BCol,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
  } from "bootstrap-vue";
  import Cleave from "vue-cleave-component";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us";
  
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  import {
    BFormDatepicker,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BForm,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  export default {
  
  
    components: {
      flatPickr,
      ToastificationContent,
      required,
      BDropdown,
      BDropdownItem,
      BDropdownItemButton,
      BFormInvalidFeedback,
      VueGoodTable,
      BForm,
      ValidationObserver,
      ValidationProvider,
  
      BCardCode,
      BButton,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
      Cleave,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BRow,
  
      BCol,
    },
  
    data() {
      return {
        file: "",
        body:'',
        item:'',
    optionitem:[],
        item_name:'',
      
        date:'',
        notes:'',
        employe: "",
        id: "",
        searchTerm: "",
        deadline:'',
      notes:'',
      transaction_id: 1,
      };
    },
  
    created() {
   this.getPact()
  

  
    },
   
  
    methods: {
        getPact() {
    
    this.$http.get(`/api/v1/purchase-order/${this.$route.params.id}`).then((res) => {
      //console.log(res.data.data);
      this.notes = res.data.data.notes;
      this.deadline=res.data.data.deadline;
   
    });
  },
      getemploye() {
        this.optionemploye=[]
        this.$http.get("/api/v1/get-all-users").then((res) => {
          //console.log(res);
          let employe = res.data;
          employe.forEach((el) => {
          this.optionemploye.push({ value: el.id, label: `${el.first_name} ${el.last_name} `
 });
          });
          //console.log(this.optionemploye);
        });
      },
     
    
      update() {
        return new Promise((resolve, reject) => {
          this.$refs.simple
            .validate()
            .then((success) => {
              if (success) {
       
                let data={
      transaction_id:this.transaction_id,
      deadline:this.deadline,
      notes:this.notes
    }
        this.$http.put(`/api/v1/purchase-order/${this.$route.params.id}`, data).then((res) => {
          //console.log(res);
   
          this.$swal({
            title: "",
            text: "تمت تعديل  بنجاح",
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
         
        });
      }})})
      },
      
  
      getPacts() {
        this.$http.get("/api/v1/pact").then((res) => {
          //console.log('pact', res.data.data)
          this.rows = res.data.data;
        });
      },
  
      //
    },
  };
  </script> 
  
  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }
  
  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>